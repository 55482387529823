// Needs refactoring
// stylelint-disable selector-max-type
.pagination {
  @include clearfix;

  a,
  span,
  em {
    position: relative;
    float: left;
    // stylelint-disable-next-line primer/spacing
    padding: 7px 12px;
    // stylelint-disable-next-line primer/spacing
    margin-left: -1px;
    // stylelint-disable-next-line primer/typography
    font-size: 13px;
    font-style: normal;
    font-weight: $font-weight-bold;
    color: $text-blue;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background: $bg-white; // Reset default gradient backgrounds and colors
    border: $border-width $border-style $border-gray;

    &:first-child {
      margin-left: 0;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    // Bring any button into forefront for proper borders given negative margin below
    &:hover,
    &:focus {
      z-index: 2;
      text-decoration: none;
      // stylelint-disable-next-line primer/colors
      background-color: darken($gray-100, 2%);
      border-color: $border-gray;
    }
  }

  .selected { z-index: 3; }

  .current,
  .current:hover,
  [aria-current],
  [aria-current]:hover {
    z-index: 3;
    color: $text-white;
    background-color: $bg-blue;
    border-color: $border-blue;
  }

  .gap,
  .disabled,
  [aria-disabled=true],
  .gap:hover,
  .disabled:hover,
  [aria-disabled=true]:hover {
    // stylelint-disable-next-line primer/colors
    color: $gray-300;
    cursor: default;
    background-color: $bg-gray-light;
  }
}

// Unified centered pagination across the site
.paginate-container {
  // stylelint-disable-next-line primer/spacing
  margin-top: 20px;
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 15px;
  text-align: center;

  .pagination {
    display: inline-block;
  }
}
