// Miscellaneous variables

// Border size
$border-width: 1px !default;
$border-color: $border-gray !default;
$border-style: solid !default;
$border: $border-width $border-color $border-style !default;
$border-radius: 3px !default;

// Box shadow
$box-shadow: 0 1px 1px rgba($black, 0.1) !default;
$box-shadow-medium: 0 1px 5px $black-fade-15 !default;
$box-shadow-large: 0 1px 15px $black-fade-15 !default;
$box-shadow-extra-large: 0 10px 50px rgba($black, 0.07) !default;

// Button and form variables
$form-control-shadow: inset 0 1px 2px rgba($black, 0.075) !default;
$btn-input-focus-shadow: 0 0 0 0.2em rgba($blue, 0.3) !default;
$btn-active-shadow: inset 0 0.15em 0.3em $black-fade-15 !default;

// Tooltips
$tooltip-max-width: 250px !default;
$tooltip-background-color: $black !default;
$tooltip-text-color: $white !default;
$tooltip-delay: 0.4s !default;
$tooltip-duration: 0.1s !default;
