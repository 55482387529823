// Margin spacer utilities for marketing
// Utilities only added for y-direction margin (i.e. top & bottom)

@each $breakpoint, $variant in $responsive-variants {
  @include breakpoint($breakpoint) {
    @each $scale, $size in $marketing-all-spacers {

      .mt#{$variant}-#{$scale} { margin-top: $size !important; }
      .mb#{$variant}-#{$scale} { margin-bottom: $size !important; }

      .my#{$variant}-#{$scale} {
        margin-top: $size !important;
        margin-bottom: $size !important;
      }
    }
  }
}
