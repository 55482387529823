// Outer wrapper
// stylelint-disable selector-max-type
.tabnav {
  margin-top: 0;
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 15px;
  border-bottom: $border-width $border-style $border-gray-dark;

  .Counter {
    // stylelint-disable-next-line primer/spacing
    margin-left: 5px;
  }
}

.tabnav-tabs {
  // stylelint-disable-next-line primer/spacing
  margin-bottom: -1px;
}

.tabnav-tab {
  display: inline-block;
  padding: $spacer-2 12px;
  font-size: $h5-size;
  // stylelint-disable-next-line primer/typography
  line-height: 20px;
  color: $text-gray;
  text-decoration: none;
  background-color: transparent;
  border: $border-width $border-style transparent;
  border-bottom: 0;

  &.selected,
  &[aria-selected=true],
  &[aria-current] {
    color: $text-gray-dark;
    background-color: $bg-white;
    border-color: $border-gray-dark;
    border-radius: $border-radius $border-radius 0 0;
  }

  &:hover,
  &:focus {
    color: $text-gray-dark;
    text-decoration: none;
  }
}

// Tabnav extras
//
// Tabnav extras are non-tab elements that sit in the tabnav. Usually they're
// inline text or links.

.tabnav-extra {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  padding-top: 10px;
  // stylelint-disable-next-line primer/spacing
  margin-left: 10px;
  font-size: $font-size-small;
  color: $text-gray;

  > .octicon {
    // stylelint-disable-next-line primer/spacing
    margin-right: 2px;
  }
}

// When tabnav-extra are anchors
// stylelint-disable selector-no-qualifying-type
a.tabnav-extra:hover {
  color: $text-blue;
  text-decoration: none;
}
// stylelint-enable selector-no-qualifying-type

// Tabnav buttons
//
// For when there are multiple buttons, space them out appropriately. Requires
// the buttons to be floated or inline-block.

.tabnav-btn {
  // stylelint-disable-next-line primer/spacing
  margin-left: 10px;
}
