.avatar {
  display: inline-block;
  overflow: hidden; // Ensure page layout in Firefox should images fail to load
  line-height: $lh-condensed-ultra;
  vertical-align: middle;
  border-radius: $border-radius;
}

// stylelint-disable-next-line primer/borders
.avatar-small { border-radius: 2px; }

.avatar-link {
  float: left;
  line-height: $lh-condensed-ultra;
}

// User for example on /stars and /user for grids of avatars
.avatar-group-item {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 3px;
}
