.UnderlineNav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: $border;
  justify-content: space-between;
}

.UnderlineNav-body {
  display: flex;
}

.UnderlineNav-item {
  padding: $spacer-3 $spacer-2;
  margin-right: $spacer-3;
  font-size: $body-font-size;
  line-height: $lh-default;
  color: $text-gray;
  text-align: center;
  border: 0;
  // stylelint-disable-next-line primer/borders
  border-bottom: 2px $border-style transparent;

  &:hover,
  &:focus {
    color: $text-gray-dark;
    text-decoration: none;
    border-bottom-color: $border-gray-dark;
    transition: 0.2s ease;

    .UnderlineNav-octicon {
      color: $text-gray-light;
    }
  }

  &.selected,
  &[role=tab][aria-selected=true],
  &[aria-current] {
    font-weight: $font-weight-bold;
    color: $text-gray-dark;
    // stylelint-disable-next-line primer/borders
    border-bottom-color: $orange-600;

    .UnderlineNav-octicon {
      color: $text-gray-light;
    }
  }
}

.UnderlineNav--right {
  justify-content: flex-end;

  .UnderlineNav-item {
    margin-right: 0;
    margin-left: $spacer-3;
  }

  .UnderlineNav-actions {
    flex: 1 1 auto;
  }
}

.UnderlineNav-actions {
  align-self: center;
}

.UnderlineNav--full {
  display: block;
}

.UnderlineNav-octicon {
  // stylelint-disable-next-line primer/colors
  color: $gray-400;
}

.UnderlineNav-container {
  display: flex;
  justify-content: space-between;
}
