// .avatar-parent-child is when you see a small avatar at the bottom right
// corner of a larger avatar.
//
// No Styleguide version
.avatar-parent-child {
  position: relative;
}

.avatar-child {
  position: absolute;
  right: -15%;
  bottom: -9%;
  background-color: $bg-white; // For transparent backgrounds
  // stylelint-disable-next-line primer/borders
  border-radius: 2px;
  // stylelint-disable-next-line primer/box-shadow
  box-shadow: -2px -2px 0 rgba($white, 0.8);
}
