// Progress

.Progress {
  display: flex;
  height: 8px;
  overflow: hidden;
  // stylelint-disable-next-line primer/colors
  background-color: $gray-200;
  border-radius: $border-radius;
}

.Progress--large {
  height: 10px;
}

.Progress--small {
  height: 5px;
}
